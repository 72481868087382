<template>
  <div class="order-form">
    <validation-observer v-slot="{invalid, handleSubmit}" ref="observer">

      <div class="form-messages" ref="messages">
        <b-notification type="is-danger" @close="onCloseNotification" has-icon="" v-model="status.error">
          <p>Ошибка! Не удалось отправить сообщение.</p>
          <p>Попробуйте снова, если ошибка повторяется, обновите страницу.</p>
          <template v-if="showExtendedError">
            <p>Описание ошибки:</p>
            <span v-for="(item, index) in errors" :key="index">
              <strong>{{index}}: {{item}}</strong>
            </span>
          </template>
        </b-notification>
      </div>

      <b-notification type="is-success" @close="onCloseNotification" has-icon="" v-model="status.success">
        <p>Спасибо! Ваше сообщение отправлено.</p>
        <p>Постараемся ответить как можно быстрее.</p>
      </b-notification>

      <div class="form-wrapper" v-if="isNotSuccess">
        <form @submit.prevent.stop="handleSubmit(submit)" class="form" ref="form" enctype="multipart/form-data">
          <input type="hidden" name="WEB_FORM_ID" :value="formId">
          <div class="columns is-multiline">
            <div class="column is-6 pb-0">
              <validation-provider v-slot="v" rules="required"  name="Ваше имя" slim>
                <p class="has-text-danger is-size-7">{{v.errors[0]}}</p>
                <b-field  :type="{ 'is-danger': v.invalid && v.dirty }" class="mb-3">
                  <b-input name="form_text_5" placeholder="Ваше имя *" v-model="formData.name"></b-input>
                </b-field>
              </validation-provider>
            </div>
            <div class="column is-6 pb-0">
              <validation-provider v-slot="v" name="Название компании" slim>
                <p class="has-text-danger is-size-7">{{v.errors[0]}}</p>
                <b-field  :type="{ 'is-danger': v.invalid && v.dirty }" class="mb-3">
                  <b-input name="form_text_6" placeholder="Название компании" v-model="formData.company"></b-input>
                </b-field>
              </validation-provider>
            </div>

            <div class="column is-6 pt-0">
              <validation-provider v-slot="v"  name="Телефон" slim>
                <p class="has-text-danger is-size-7">{{v.errors[0]}}</p>
                <b-field class="mb-3">
                  <b-input name="form_text_7" placeholder="Номер телефона" v-model="formData.phone"></b-input>
                </b-field>

              </validation-provider>

              <validation-provider v-slot="v" rules="required|email" name="Email" slim>
                <p class="has-text-danger is-size-7">{{v.errors[0]}}</p>
                <b-field :type="{ 'is-danger': v.invalid && v.dirty }" class="mb-3">
                  <b-input name="form_email_8" placeholder="Email *" v-model="formData.email"></b-input>
                </b-field>

              </validation-provider>

              <input v-if="formData.product && selectedProduct" type="hidden" name="form_text_9" :value="selectedProduct.NAME">

              <fieldset class="fieldset-production-list">
                <legend>Доступно для заказа</legend>
                <validation-provider v-slot="v" rules="required" name="Продукция" slim>
                  <p class="has-text-danger is-size-7">{{v.errors[0]}}</p>
                  <b-field  type="{'is-danger': v.invalid}" class="mb-3">
                    <b-select v-model="formData.product" expanded placeholder="Выберите продукцию *">
                      <template v-for="option in products">
                        <!-- <option v-if="option.active" :selected="option.selected" :key="option.id" :value="option.name"> {{option.name}}</option> -->
                        <option :key="option.ID" :value="option.ID">
                          {{option.NAME}}
                          <template v-if="option.UF_PART_UNITS">
                            ({{option.UF_PART_UNITS.NAME}})
                          </template>
                        </option>
                      </template>
                    </b-select>
                  </b-field>
                </validation-provider>

                 <validation-provider v-slot="v" :rules="partRules"  name="Партия заказа" slim>
                  <b-message v-if="formData.product" :type="(v.invalid) ? 'is-danger' : 'is-info'" size="is-small" class="mb-4">
                    <!-- Единицы измерения заказа: <strong>{{selectedProduct.UF_PART_UNITS.NAME}}</strong>,-->
                    <span v-if="selectedProduct.UF_PART_MIN">
                      Минимальный заказ:
                      <strong>
                        {{selectedProduct.UF_PART_MIN}}
                        {{num2str(selectedProduct.UF_PART_MIN, selectedProduct.UF_PART_UNITS.dec)}}
                      </strong>
                    </span>
                    <span v-if="selectedProduct.UF_PART_COMMENT">
                      ({{ selectedProduct.UF_PART_COMMENT }})
                    </span>
                  </b-message>

                  <b-field v-if="formData.product" :type="{ 'is-danger': v.invalid && v.dirty }" class="mb-3">
                    <b-input type="number" name="form_text_26" placeholder="Партия заказа *" v-model="formData.quantity" expanded></b-input>
                    <p class="control">
                      <span class="button is-static">
                        {{ productUnitDeclension }}
                      </span>
                    </p>
                  </b-field>

                  <p class="has-text-danger help">
                    {{v.errors[0]}}
                  </p>
                </validation-provider>
              </fieldset>
            </div>

            <div class="column is-6 pt-0">
              <validation-provider v-slot="v" rules="max:2000" slim name="Примечание">
                <p class="has-text-danger is-size-7">{{v.errors[0]}}</p>
                <b-field  type="{'is-danger': v.invalid}" class="mb-3">
                  <b-input name="form_textarea_13" placeholder="Комментарий к заказу" type="textarea" rows="7" v-model="formData.message"></b-input>
                </b-field>
              </validation-provider>
            </div>
          </div>

          <p class="is-size-7">* - обязательные поля</p>

          <b-message style="margin-top: 2rem;" class="is-light">
            <p class="help">
              Отправляя сообщение вы принимаете <a target="_blank" href="https://docs.google.com/document/d/1GC6HBtVHwgVvXMdzLTm_BwwacyWetWTUyLa2XCKJh3U/">Положение на обработку персональных данных и получение информации.</a>. Если вы не
              согласны <a target="_blank" href="https://docs.google.com/document/d/1GC6HBtVHwgVvXMdzLTm_BwwacyWetWTUyLa2XCKJh3U/">с условиями
              положения</a>, не отправляйте форму.
              Ваши данные будут использованы только для связи с вами, для ответа на ваше сообщение.
            </p>
          </b-message>

          <div class="controls">
            <b-button type="is-warning has-text-primary " native-type="submit" :disabled="invalid" :class="{'is-loading': isLock}">Отправить</b-button>
          </div>

          <!-- доп. информация о заказе -->
          <template v-if="selectedProduct">
            <input type="hidden" name="form_text_27" :value="selectedProduct.UF_PART_MIN">
            <input type="hidden" name="form_text_28" :value="selectedProduct.UF_PART_COMMENT">
            <input type="hidden" name="form_text_29" :value="selectedProduct.UF_PART_DEFAULT">
            <input type="hidden" name="form_text_30" :value="selectedProduct.UF_PART_UNITS.NAME">
          </template>
        </form>

      </div>
    </validation-observer>
  </div>
</template>
<script>
import Vue from 'vue'
import FormCommonMixin from '@/mixins/form-common-mixin'

export default Vue.extend({
  mixins: [FormCommonMixin],
  data() {
    return {
      formId: 2,
      ready: false,
      products: [],
      // default part rules
      partRules: {
        required: true,
        min_value: 1,
        numeric: true
      },
      formData: {
        quantity: 0
      }
    }
  },
  computed: {
    productUnitDeclension() {
      if (!this.selectedProduct) {
        return '---'
      }

      return this.num2str(
        this.formData.quantity,
        this.selectedProduct.UF_PART_UNITS.dec
      );
    },
    selectedProduct () {
      const vm = this
      if (!this.formData.product) return null
      return this.products.find((x) => {
        return x.ID === vm.formData.product
      })
    }
  },
  watch: {
    'formData.product': function(newVal) {

      /**
       * @todo реальные правила проверки
       *
       * пока это заготовка, не более
       *
       */
      if (newVal > 0 && this.selectedProduct) {
        if (this.formData.quantity === 0) {
          this.formData.quantity = this.selectedProduct.UF_PART_MIN
        } else if (parseFloat(this.formData.quantity) < parseFloat(this.selectedProduct.UF_PART_MIN)) {
          this.formData.quantity = this.selectedProduct.UF_PART_MIN
        } else {
          this.formData.quantity = this.selectedProduct.UF_PART_MIN
        }

        /**
         * update rules
         */
        this.partRules.min_value = parseFloat(this.selectedProduct.UF_PART_MIN)
      }
    }
  },
  mounted () {
    // console.log('mounted')
    const vm = this
    /**
     * загрузка данных из Битрикс
     * для отображения реальных данных
     */
    window['BX'].ajax.runAction('platform:main.api.catalog.getProductSelector')
    .then(({ data }) => {
       vm.products = data.s
    })
    .catch(({ errors }) => console.log(errors))
  },
  methods: {
    submit () {
      if (!window['BX']) {
        console.error('BX not found.')
        return
      }

      const vm = this

      /**
       * перед отправкой необходимо
       * очистить ошибки и состояния
       */
      this.status.dirty = true
      this.status.success = false
      this.status.error = false
      this.status.pristine = false
      this.errors.splice(0, this.errors.length + 1)

      const form = new FormData(this.$refs.form)
      this.$store.commit('lock')

      window['BX']
        .ajax
        .runAction('platform:main.api.catalog.submitOrder', {
          data: form
        })
        .then(() => {
          // console.log('success')

          vm.$store.commit('unlock')
          vm.status.success = true
          vm.resetForm()
        })
        .catch((errors) => {
          // console.log('error')

          /**
           * @todo parse errors
           */
          vm.$store.commit('unlock')
          vm.errors.push(errors)
          vm.status.error = true
        })
    }
  }
})
</script>

<style lang="sass" scoped>
@import 'helpers'
.site-feedback-form
  //background: #fff
.form-header
  margin-bottom: 1rem
  padding-bottom: 1rem
  border-bottom: 1px solid #eee
  background: #fff
/** in modal  */
.form-in-modal .site-feedback-form
  .form-wrapper
    background: #fff
    padding: 2rem
  .form-header
    background: $primary
    p.title
      padding-left: 2rem
      padding-top: 1rem
      color: $primary-invert
.fieldset-production-list
  background-color: #fff
  border-radius: 6px
  box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02)
  color: #4a4a4a
  display: block
  padding: 1.25rem
  border: 1px solid #ccc
  margin-bottom: 1rem
  background-color: #fdfdfd
.fieldset  > legend
  color: #363636
  display: block
  font-size: 1rem
  font-weight: 700
  background-color: #fff
  padding: 0 5px
  width: max-content
  border: 0 none

</style>
